<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <CIcon name="cil-grid"/>
          Báo cáo Chương trình Xúc tiến đầu tư Quốc gia
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol lg="6">
              <CInput label="Tên hoạt động" horizontal :value.sync="filterModel.soVanBan"/>
            </CCol>
            <CCol lg="6">
              <CSelect  label="Năm chương trình" horizontal :options="listYear" :value.sync="filterModel.nam_ChuongTrinh" />
            </CCol>
          </CRow>
          <CRow>
            <CCol lg="6">
              <CInput label="Số văn bản" horizontal :value.sync="filterModel.soVanBan"/>
            </CCol>
            <CCol lg="6">
              <CSelect label="Trạng thái" horizontal :options="optionsTrangThai" :value.sync="filterModel.trangThai"/>
            </CCol>
          </CRow>
          <CRow>
            <CCol lg="6">
              <CInput  label="Đơn vị chủ trì" horizontal :value.sync="filterModel.coQuanChuTri" />
            </CCol>
            <CCol lg="6">
              <CInput  label="Đơn vị đề xuất" horizontal :value.sync="filterModel.donViDeXuat" />
            </CCol>
          </CRow>
          <CRow>
            <CCol col="12">
              <CButton class="mb-3 mr-3" color="primary" @click="getBaoCaoTongHop1()">Xuất báo cáo tổng hợp đề xuất (mẫu 1.1.1.1)</CButton>
              <a :href="getDownloadURL(baoCaoTongHop1111url)" target="_blank">{{ baoCaoTongHop1111url }}</a>
<!--              <CButton class="mb-3 mr-3" color="primary" :disabled="isFetchingList" @click="search">-->
<!--                <CIcon name="cil-search"/>&nbsp;Tìm kiếm-->
<!--              </CButton>-->
<!--              <CButton class="mb-3" color="primary" @click="createItem">-->
<!--                <CIcon name="cil-plus"/> Thêm mới-->
<!--              </CButton>-->
            </CCol>
            <CCol col="12">
              <CButton class="mb-3 mr-3" color="primary" @click="getBaoCaoTongHop2()">Xuất báo cáo tổng hợp đề xuất (mẫu 1.1.1.2)</CButton>
              <a :href="getDownloadURL(baoCaoTongHop1112url)" target="_blank">{{ baoCaoTongHop1112url }}</a>
            </CCol>
            <CCol col="12">
              <CButton class="mb-3 mr-3" color="primary" @click="getBaoCaoTongHop4()">Xuất báo cáo tổng hợp đề xuất (mẫu 1.1.1.4)</CButton>
              <a :href="getDownloadURL(baoCaoTongHop1114url)" target="_blank">{{ baoCaoTongHop1114url }}</a>
            </CCol>
          </CRow>
          <div class="table-responsive position-relative">
            <vue-scrolling-table>
              <template slot="thead" style="background-color: #ced2d8 !important;">
                <tr class="">
                  <th class="text-center align-middle fs-12 w-50px" rowspan="3">
                    STT
                  </th>
                  <th class="p-1 text-center fs-13 w-120" rowspan="3">Tên hoạt động</th>
                  <th class="p-1 text-center fs-13 w-120" rowspan="3">Loại hoạt động</th>
                  <th class="p-1 text-center fs-13 w-120" rowspan="3">Dự kiến thời gian tổ chức thực hiện
                    (tháng, năm)
                  </th>
                  <th class="p-1 text-center fs-13 w-120" rowspan="3">Đơn vị chủ trì thực hiện</th>
                  <th class="p-1 text-center fs-13 w-120" colspan="2">Địa điểm tổ chức</th>
                  <th class="p-1 text-center fs-13 w-120" rowspan="3">Mục đích/Nội dung hoạt động</th>
                  <th class="p-1 text-center fs-13 w-120" rowspan="3">Ngành/Lĩnh vực kêu gọi đầu tư (ngành
                    ISIC cấp 2)
                  </th>
                  <th class="p-1 text-center fs-13 w-120" rowspan="3">Địa bàn/Tỉnh/Vùng kêu gọi đầu tư</th>
                  <th class="p-1 text-center fs-13 w-120" rowspan="3">Căn cứ triển khai hoạt động</th>
                  <th class="p-1 text-center fs-13 w-120" colspan="4">Đơn vị phối hợp</th>
                  <th class="p-1 text-center fs-13 w-120" rowspan="3">Nguồn kinh phí</th>
                  <th class="p-1 text-center fs-13 w-120" colspan="4">Kinh phí (đơn vị VNĐ)</th>
                  <th class="p-1 text-center fs-13 w-120" rowspan="3">Ghi chú</th>
                </tr>
                <tr>
                  <th class="p-1 text-center fs-13 w-120" rowspan="2">Trong nước (địa phương)</th>
                  <th class="p-1 text-center fs-13 w-120" rowspan="2">Nước ngoài (quốc gia)</th>
                  <th class="p-1 text-center fs-13 w-120" rowspan="2">Tên tổ chức/Cơ quan trong nước</th>
                  <th class="p-1 text-center fs-13 w-120" rowspan="2">Tên tổ chức/Cơ quan nước ngoài</th>
                  <th class="p-1 text-center fs-13 w-120" rowspan="2">Doanh nghiệp trong nước</th>
                  <th class="p-1 text-center fs-13 w-120" rowspan="2">Doanh nghiệp nước ngoài</th>
                  <th class="p-1 text-center fs-13 w-120" rowspan="2">Chương trình XTĐT Quốc gia</th>
                  <th class="p-1 text-center fs-13 w-120" rowspan="2">Ngân sách địa phương</th>
                  <th class="p-1 text-center fs-13 w-120" rowspan="2">Khác (tài trợ)</th>
                  <th class="p-1 text-center fs-13 w-120" rowspan="2">Tổng kinh phí</th>
                </tr>
                <!--                            <tr class="bg-secondary">-->
                <!--                              <th class="p-1 text-center fs-13 w-90px">Trong nước (tên doanh nghiệp)</th>-->
                <!--                              <th class="p-1 text-center fs-13 w-90px">Nước ngoài (tên doanh nghiệp)</th>-->
                <!--                            </tr>-->
              </template>
              <template slot="tbody">
                <template v-if="chiTietDeXuatCTXTDTQuocGia.length">
                  <tr v-for="(it, indx) in chiTietDeXuatCTXTDTQuocGia" v-bind:key="it.id">
                    <td class="text-center p-1 fs-13 align-middle w-50px">{{ indx + 1 }}</td>
                    <td class="text-center p-1 fs-13 align-middle w-120">
                      {{ it.tenHoatDong }}
                    </td>
                    <td class="text-center p-1 fs-13 align-middle w-120">
                      {{ it.loaiHoatDong }}
                    </td>
                    <td class="text-center p-1 fs-13 align-middle w-120">
                      {{ it.duKienThoiGianToChuc }}
                    </td>
                    <td class="text-center p-1 fs-13 align-middle w-120">
                      {{ it.donViChuTriThucHien }}
                    </td>
                    <td class="text-center p-1 fs-13 align-middle w-120">
                      {{ it.diaDiem_TrongNuoc }}
                    </td>
                    <td class="text-center p-1 fs-13 align-middle w-120">
                      {{ it.diaDiem_NuocNgoai }}
                    </td>
                    <td class="text-center p-1 fs-13 align-middle w-120">
                      {{ it.mucDich_NoiDungHoatDong }}
                    </td>
                    <td class="text-center p-1 fs-13 align-middle w-120">
                      <span v-if="it.nganhLinhVucKeuGoiDauTuId === 1">Thủy sản</span>
                      <span v-if="it.nganhLinhVucKeuGoiDauTuId === 2">Du lịch</span>
                    </td>
                    <td class="text-center p-1 fs-13 align-middle w-120">
                      {{ it.diaBanKeuGoiDauTu }}
                    </td>
                    <td class="text-center p-1 fs-13 align-middle w-120">
                      {{ it.canCuTrienKhai }}
                    </td>
                    <td class="text-center p-1 fs-13 align-middle w-120">
                      {{ it.toChucCoQuan_TrongNuoc }}
                    </td>
                    <td class="text-center p-1 fs-13 align-middle w-120">
                      {{ it.toChucCoQuan_NuocNgoai }}
                    </td>
                    <td class="text-center p-1 fs-13 align-middle w-120">
                      {{ it.doanhNghiep_TrongNuoc }}
                    </td>
                    <td class="text-center p-1 fs-13 align-middle w-120">
                      {{ it.doanhNghiep_NuocNgoai }}
                    </td>
                    <td class="text-center p-1 fs-13 align-middle w-120">
                      <span v-if="it.nguonKinhPhi === 1">Ngân sách địa phương</span>
                      <span v-if="it.nguonKinhPhi === 2">Tài trợ</span>
                    </td>
                    <td class="text-center p-1 fs-13 align-middle w-120">
                      {{formatNumber(it.kinhPhi_ChuongTrinhXTDTQG) }}
                    </td>
                    <td class="text-center p-1 fs-13 align-middle w-120">
                      {{formatNumber(it.kinhPhi_NganSachDiaPhuong)}}
                    </td>
                    <td class="text-center p-1 fs-13 align-middle w-120">
                      {{formatNumber(it.kinhPhi_Khac)}}
                    </td>
                    <td class="text-center p-1 fs-13 align-middle w-120">
                      {{ formatNumber(Number(it.kinhPhi_ChuongTrinhXTDTQG) + Number(it.kinhPhi_NganSachDiaPhuong) + Number(it.kinhPhi_Khac)) }}
                    </td>
                    <td class="text-center p-1 fs-13 align-middle w-120">
                      <template v-if="it.trangThai === 0">Tất cả</template>
                      <template v-if="it.trangThai === 1">Soạn thảo</template>
                      <template v-if="it.trangThai === 2">Chờ phê duyệt</template>
                      <template v-if="it.trangThai === 3">Đã phê duyệt</template>
                      <template v-if="it.trangThai === 4">Yêu cầu chỉnh sửa</template>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-center p-1 fs-13 align-middle" colspan="20">Chưa có dữ liệu</td>
                  </tr>
                </template>
              </template>
            </vue-scrolling-table>
          </div>
        </CCardBody>
      </CCard>
      <DeleteConfirmationModal
        title="Xóa đề xuất chương trình xúc tiến đầu tư của Bộ, cơ quan ngang Bộ, UBND Tỉnh"
        :show.sync="isDeleteConfirmationModalOpen"
        :is-deleting="isDeleting"
        :item="item" @confirm-destroy="confirmDestroy"/>
      <ApproveConfirmationModal2
        title="Phê duyệt đề xuất chương trình xúc tiến đầu tư của Bộ, cơ quan ngang Bộ, UBND Tỉnh"
        :show.sync="isApproveConfirmationModalOpen"
        :is-approving="isApproving"
        :item="approvingItem" @confirm-approve="confirmApprove"
        v-on:data="data($event)"
        :type="type"
      />
    </CCol>
  </CRow>
</template>

<script>
import * as types from '@/store/modules/DeXuatCTXTDTQG/actionTypes'
import { mapActions, mapGetters } from 'vuex'
import { displayLabel, loaiDonVis } from '@/shared/appConstants'
import { enums } from '@/shared/enums'
import {
  APPROVE_DEXUATCTXTDTQUOCGIA,
  DELETE_DEXUATCTXTDTQUOCGIA, GET_TONGHOPDEXUAT1111, GET_TONGHOPDEXUAT1112, GET_TONGHOPDEXUAT1114
} from '@/store/modules/DeXuatCTXTDTQG/actionTypes'
import moment from 'moment'
import VueScrollingTable from 'vue-scrolling-table'
import { v4 as uuidv4 } from 'uuid'
import appConfig from '@/shared/appConfig'

export default {
  name: 'DeXuatCTXTDTQG',
  components: {
    VueScrollingTable
  },
  data () {
    return {
      filterModel: {
        soVanBan: '',
        trangThai: 0,
        nam_ChuongTrinh: Number(this.getYear(new Date())),
        tenCoQuanChuTri: '',
        donViDeXuat: '',
        orderBy: null,
        pageNumber: 1,
        pageSize: 15
      },
      optionsLoaiDonVi: loaiDonVis,
      optionsTrangThai: enums.trangThaiDeXuatCTXTDT,
      listItem: [],
      listYear: [],
      isDeleteConfirmationModalOpen: false,
      isApproveConfirmationModalOpen: false,
      approvingItem: {},
      type: 'DeXuatCTXTDTQuocGia',
      approvingItemData: {
        loaiPheDuyet: 0,
        noiDung: '',
        dinhKem: '',
        dexuatguid: 0
      },
      chiTietDeXuatCTXTDTQuocGia: [
        {
          deXuatGuid: uuidv4(),
          guid: uuidv4(),
          tenHoatDong: 'Đầu tư',
          loaiHoatDong: 1,
          duKienThoiGianToChuc: '09/2021',
          donViChuTriThucHien: 'Cơ quan',
          diaDiem_TrongNuoc: 'Đà Nẵng',
          diaDiem_NuocNgoai: 'Đức',
          mucDich_NoiDungHoatDong: 'Phát triển',
          nganhLinhVucKeuGoiDauTuId: 1,
          diaBanKeuGoiDauTu: 'Đà Nẵng',
          canCuTrienKhai: 'Quảng bá',
          toChucCoQuan_TrongNuoc: 'Tổ chức',
          toChucCoQuan_NuocNgoai: 'Tổ chức',
          doanhNghiep_TrongNuoc: 'Công ty',
          doanhNghiep_NuocNgoai: 'Công ty',
          nguonKinhPhi: 2,
          kinhPhi_ChuongTrinhXTDTQG: 1500000,
          kinhPhi_NganSachDiaPhuong: 1200000,
          kinhPhi_Khac: 1000000,
          soLanHieuChinh: 0,
          trangThai: 2
        },
        {
          deXuatGuid: uuidv4(),
          guid: uuidv4(),
          tenHoatDong: 'Đầu tư',
          loaiHoatDong: 1,
          duKienThoiGianToChuc: '09/2021',
          donViChuTriThucHien: 'Cơ quan',
          diaDiem_TrongNuoc: 'Đà Nẵng',
          diaDiem_NuocNgoai: 'Hà LAn',
          mucDich_NoiDungHoatDong: 'Phát triển',
          nganhLinhVucKeuGoiDauTuId: 1,
          diaBanKeuGoiDauTu: 'Quảng Bình',
          canCuTrienKhai: 'Quảng bá',
          toChucCoQuan_TrongNuoc: 'Tổ chức',
          toChucCoQuan_NuocNgoai: 'Tổ chức',
          doanhNghiep_TrongNuoc: 'Công ty',
          doanhNghiep_NuocNgoai: 'Công ty',
          nguonKinhPhi: 1,
          kinhPhi_ChuongTrinhXTDTQG: 1500000,
          kinhPhi_NganSachDiaPhuong: 1800000,
          kinhPhi_Khac: 1200000,
          soLanHieuChinh: 0,
          trangThai: 4
        }
      ],
      baoCaoTongHop1111url: '',
      baoCaoTongHop1112url: '',
      baoCaoTongHop1114url: ''
    }
  },
  computed: {
    // ...mapGetters('deXuatCTXTDT', {
    //   dataSourcePaging: 'dataSourcePaging',
    //   isFetchingList: 'isFetchingList',
    //   deletedItem: 'deletedItem',
    //   isDeleting: 'isDeleting',
    //   isDeleteConfirmationModalOpen: 'isDeleteConfirmationModalOpen',
    //   responseResult: 'responseResult'
    // })
    ...mapGetters('deXuatCTXTDTQG', {
      dataSourcePaging: 'dataSourcePaging',
      isFetchingList: 'isFetchingList',
      responseResult: 'responseResult',
      isDeleting: 'isDeleting',
      item: 'detailItem',
      isApproving: 'isApproving',
      tongHopDeXuat1111: 'tongHopDeXuat1111',
      tongHopDeXuat1112: 'tongHopDeXuat1112',
      tongHopDeXuat1114: 'tongHopDeXuat1114'
    }),
    firstItemIndex () {
      return (this.filterModel.pageNumber - 1) * this.filterModel.pageSize || 0
    }
  },
  methods: {
    ...mapActions('deXuatCTXTDTQG', {
      getListPaging: types.GET_LIST_DEXUATCTXTDTQUOCGIA_PAGING,
      deleteItem: DELETE_DEXUATCTXTDTQUOCGIA,
      approveItem: APPROVE_DEXUATCTXTDTQUOCGIA,
      getBaoCaoTongHopDeXuat1111: GET_TONGHOPDEXUAT1111,
      getBaoCaoTongHopDeXuat1112: GET_TONGHOPDEXUAT1112,
      getBaoCaoTongHopDeXuat1114: GET_TONGHOPDEXUAT1114
    }),
    displayLabel,
    async fetchListItem () {
      await this.getListPaging(this.filterModel)
    },
    async getBaoCaoTongHop1 () {
      const data = {
        nam: this.filterModel.nam_ChuongTrinh
      }
      await this.getBaoCaoTongHopDeXuat1111(data)
      if (this.responseResult.error) {
        this.$toast.error(this.responseResult.message)
      } else {
        this.$toast.success(this.responseResult.message)
      }
    },
    async getBaoCaoTongHop2 () {
      const data = {
        nam: this.filterModel.nam_ChuongTrinh
      }
      await this.getBaoCaoTongHopDeXuat1112(data)
      if (this.responseResult.error) {
        this.$toast.error(this.responseResult.message)
      } else {
        this.$toast.success(this.responseResult.message)
      }
    },
    async getBaoCaoTongHop4 () {
      const data = {
        nam: this.filterModel.nam_ChuongTrinh
      }
      await this.getBaoCaoTongHopDeXuat1114(data)
      if (this.responseResult.error) {
        this.$toast.error(this.responseResult.message)
      } else {
        this.$toast.success(this.responseResult.message)
      }
    },
    data (event) {
      this.approvingItemData.dexuatguid = event.dexuatguid
      this.approvingItemData.loaiPheDuyet = event.loaiPheDuyet
      this.approvingItemData.noiDung = event.noiDung
      this.approvingItemData.dinhKem = event.dinhKem
    },
    getListYear () {
      const thisyear = Number(this.getYear(new Date()))
      const startyear = 2000
      const endyear = thisyear + 10
      for (let i = startyear; i <= endyear; i++) {
        const it = {
          label: i,
          value: i
        }
        this.listYear.push(it)
      }
    },
    async search () {
      this.filterModel.pageNumber = 1
      this.filterModel.orderBy = null
      await this.fetchListItem()
    },
    createItem () {
      this.$router.push({ path: 'de-xuat-ct-xtdt-qg/them-moi' })
    },
    viewItem (item) {
      this.$router.push({ path: `de-xuat-ct-xtdt-qg/${item.id}` })
    },
    updateItem (item) {
      this.$router.push({ path: `de-xuat-ct-xtdt-qg/${item.id}/cap-nhat` })
    },
    destroyItem (item) {
      this.deletedItem = Object.assign({}, item)
      this.isDeleteConfirmationModalOpen = true
    },
    async confirmDestroy () {
      await this.deleteItem(this.deletedItem)
      if (this.responseResult.error) {
        this.$toast.error(this.responseResult.message)
      } else {
        this.$toast.success(this.responseResult.message)
        this.isDeleteConfirmationModalOpen = false
        await this.fetchListItem()
      }
    },
    confirmApproveItem (item) {
      this.approvingItem = Object.assign({}, item)
      this.isApproveConfirmationModalOpen = true
    },
    async confirmApprove () {
      await this.approveItem(this.approvingItemData)
      if (this.responseResult.error) {
        this.$toast.error(this.responseResult.message)
      } else {
        this.$toast.success(this.responseResult.message)
        this.isApproveConfirmationModalOpen = false
        await this.fetchListItem()
      }
    },
    formatDateData (v, format = 'YYYY-MM-DD H:i:s', outputFormat = 'DD/MM/YYYY') {
      if (v) {
        return moment(v, format).format(outputFormat)
      } else {
        return ''
      }
    },
    formatNumber (v) {
      if (!isNaN(v)) {
        if (v >= 0) {
          return parseInt(v).toFixed(1).replace(/(\d)(?=(\d{3})+\.)/g, '$1,').slice(0, -2)
        } else {
          return '-' + parseInt(-v).toFixed(1).replace(/(\d)(?=(\d{3})+\.)/g, '$1,').slice(0, -2)
        }
      } else {
        return 0
      }
    },
    getDownloadURL (url) {
      return url ? appConfig.BASE_HOST + url : ''
    },
    getYear: (time, format = 'DD/MM/YYYY') => time ? moment(time, format).format('YYYY') : ''
  },
  async mounted () {
    await this.fetchListItem()
    this.getListYear()
  },
  watch: {
    tongHopDeXuat1111: function (val) {
      this.baoCaoTongHop1111url = val
    },
    tongHopDeXuat1112: function (val) {
      this.baoCaoTongHop1112url = val
    },
    tongHopDeXuat1114: function (val) {
      this.baoCaoTongHop1114url = val
    }
  }
}
</script>
